import React from 'react'
const icons = {}
function Buttons({children,name,setType,value,jobType,style}) {
console.log("value",value)
console.log("jobType",jobType)
console.log("jobType==value",jobType===value)
  return (
  <div onClick={()=>setType(value)} style={{minWidth:style}} className={jobType===value?'selected-job-type':'job-type'}>
    <div className={jobType===value?'selected-icon-container':'icon-container'}>
           {children}
      </div>
      <p>{name}</p>
  </div>
  )
}

export default Buttons