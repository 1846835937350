export const translatioanAb = {
  title1: "احصل على أفضل أسعار التمويل العقاري لمنزل أحلامك",
  infoTitle:
    "يعتمد الحد الأعلى لتمويلك العقاري وقيمة الأقساط الشهرية والمعدل على:",
  infoItem1: "قطاع العمل",
  infoItem2: "الراتب",
  infoItem3: "أهلية الدعم الحكومي",
  infoText: "ابدأ الآن لتتعرف على فرص التمويل المتاحة لك",
  btn1: "ابدأ الآن",
  title2: "فضلاً أخبرنا عن عملك",
  selectButton1: "في أي قطاع تعمل؟",
  selectBtn1: "حكومي مدني",
  selectBtn2: "حكومي عسكري",
  selectBtn3: "قطاع خاص (شركات صغيرة ومتوسطة)",
  selectBtn4: "قطاع خاص (شركة كبرى)",
  selectBtn5: "متقاعد",
  Rank: "الرتبة",
  Pilote: "هل أنت طيار؟",
  inputTitle1: "اكتب اسمك",
  placeHolder1: "عبدالرحمن صالح",
  inputTitle2: "راتبك الأساسي",
  placeHolder2: "اكتب راتبك هنا",
  inputTitle3: "راتبك الشهري المودع في الحساب البنكي بعد خصم التأمينات",
  placeHolderInfo: "راتبك الشهري المودع في الحساب البنكي بعد خصم التأمينات",
  placeHolder3: "المبلغ الذي يحول الى حسابك البنكي بشكل شهري بعد خصم التأمينات",
  inputTitle4: "تاريخ بدء العمل",
  inputTitle5: "اسم البنك الذي يحول إليه راتبك الشهري",
  placeHolder5: "فضلاً اختر اسم البنك",
  selectButton2: "منذ متى وأنت في هذه الوظيفة؟",
  selectBtn6: " ١-٣ أشهر ",
  selectBtn7: " ۳-٦ أشهر ",
  selectBtn8: "٦+ أشهر",
  yes: "نعم",
  no: "لا",
  nextBtn: "التالي",
  title3: "أخبرنا عن التزاماتك",
  liabilitiesTitle:
    "ما هو إجمالي التزاماتك المالية الشهرية بالريال السعودي وعدد السنوات المتبقية لتلك الالتزامات؟",
  liabilitiesSliderTitle: "كم عدد السنوات المتبقية للالتزامات؟",
  liabilities1: "شخصي",
  liabilities2: "سيارة",
  liabilities3: "عقاري",
  liabilities4: "بطاقة إئتمان",
  add: "أضف",
  monthly: "شهريا",
  valueText: " أشهر للإكمال",
  popupname: "إضافة المسؤولية",
  installment: "القسط الشهري",
  complete: "عدد الأشهر المتبقية",
  title4: "سجل سمة",
  simahText: "هل لديك حاليًا تخلف عن سداد المدفوعات مع سمة؟",
  simahValue1: "نعم",
  simahValuw2: "لا",
  liabilitiesTable: "الالتزامات المضافة",
  title5: "أحقية برنامج الدعم السكني",
  infoHeader1: "الأمر الملكي رقم (أ/86) وتاريخ 18/04/1439هـ ينص على أنه",
  infoText1:
    "تتحمل الدولة ضريبة القيمة المضافة على مبلغ لا يتجاوز (مليون ريال) من سعر شراء المسكن الأول للمواطن، وذلك لتخفيض تكلفة تملك المسكن المناسب للمواطنين.",
  question: "هل هذا مسكنك الأول؟",
  value6: "نعم، هذا هو بيتي الأول",
  value7: "لا، لدي منزل بالفعل",
  moneyType: "ريال",
  title6: "أحقية الدعم",
  infoHeader2:
    "برنامج الدعم السكني المحدث هو أحد الحلول المقدمة لتسهيل امتلاك المسكن للمستفيد وذلك من خلال تقديم دعم مالي غير مسترد بمبلغ ثابت 100 أو 150 ألف ريال حسب مصفوفة المنتجات المعتمدة",
  infoText2:
    "وقدمت وزارة الإسكان مبلغ دعم بنسبة معينة من مبلغ الأرباح وبحد أقصى 500,000 ريال من إجمالي مبلغ التمويل.",
  Eligible: "هل أنت مستحق لبرنامج الدعم'سكني'؟",
  isMortgageReady:"هل العقار جاهز؟",
  notSure: "غير متأكد",
  title7: "تهانينا!",
  text1: "الحد الأقصى للتمويل العقاري هو",
  text2: "ليس لديك توصيات من البنوك في هذا الوقت.",
  text3:
    "تحتاج إلى اجتياز KYC by Nafad، والتحقق من تقرير سمة الخاص بك للحصول على العروض النهائية من البنوك.",
  startBtn: "العودة الي التطبيق",
  Previous: "السابق",
  preLastBtn: "احسب تمويلك",
  Emkan: "إمكان",
  lastText: "حاسبة التمويل العقاري",
  lastText2:"سوف نتواصل معك قريباً جداً",
  liabilitiesType1: "مبلغ التمويل مع التزاماتك",
  liabilitiesType2: "مبلغ التمويل بدون التزاماتك",
  liabilitiesType3: "الحد الأقصى لمبلغ التمويل العقاري",
  Up_to: "حتى",
  ammount: "المبلغ",
  month6: "6 أشهر",
  year5: "5 سنوات",
  jobTypeRequired: "يرجى اختيار نوع الوظيفة.",
  employerNameRequired: "يرجى إدخال اسمك",
  militaryRankRequired: "يرجى اختيار الرتبة العسكرية.",
  piloteRequired: "يرجى تحديد ما إذا كنت طيارًا.",
  netSalaryRequired: "يرجى إدخال راتبك الشهري.",
  salaryComparison: "الراتب الصافي لا يمكن أن يكون أقل من الراتب الأساسي.",
  basicSalaryRequired: "يرجى إدخال راتبك الأساسي.",
  jobDateRequired: "يرجى اختيار تاريخ بدء الوظيفة.",
  salaryBankRequired: "يرجى اختيار البنك الخاص براتبك.",
  liabilitiesRequired: "يرجى إدخال التزاماتك",
  typeOfMortgage:'نوع التمويل العقاري',
  typeOfMortgageOptions: {
    readyUnit: 'وحدة جاهزة',
    saleOnTheMap: 'بيع على الخريطة',
    selfBuild: 'بناء ذاتي',
    other: 'اخرى',
  },
  typeOfLoansOptions: {
    personalLoan: 'قرض شخصي',
    mortgageLoan:'قرض عقاري',
    carLoan: 'قرض السيارة',
    EmkanLoan:'إمكان',
    creditCardLoan:'بطاقة ائتمان',
    other: 'اخرى',
  },
  doYouHaveAnyLoans:'هل لديك أي قروض ؟',
  typeOfLoans:'نوع القرض',
};
