export const MilitaryArray = [
  {value: 1, label: 'Lieutenant Officer', rankType: 'Officer', retireeAge: 44},
  {value: 2, label: 'First Lieutenant Officer', rankType: 'Officer', retireeAge: 44},
  {value: 3, label: 'Captain Officer', rankType: 'Officer', retireeAge: 48},
  {value: 4, label: 'Major Officer', rankType: 'Officer', retireeAge: 50},
  {value: 5, label: 'Lieutenant Colonel Officer', rankType: 'Officer', retireeAge: 52},
  {value: 6, label: 'Colonel Officer', rankType: 'Officer', retireeAge: 54},
  {value: 7, label: 'Brigadier Officer', rankType: 'Officer', retireeAge: 56},
  {value: 8, label: 'General Officer', rankType: 'Officer', retireeAge: 58},
  {value: 9, label: 'Marshal Officer', rankType: 'Officer', retireeAge: 65},
  {value: 10, label: 'First Marshal Officer', rankType: 'Officer', retireeAge: 65},
  {value: 11, label: 'Lieutenant Officer', rankType: 'Officer', retireeAge: 42},
  {value: 12, label: 'First Lieutenant Officer', rankType: 'Officer', retireeAge: 42},
  {value: 13, label: 'Captain Officer', rankType: 'Officer', retireeAge: 46},
  {value: 14, label: 'Major Officer', rankType: 'Officer', retireeAge: 48},
  {value: 15, label: 'Lieutenant Colonel Officer', rankType: 'Officer', retireeAge: 50},
  {value: 16, label: 'Colonel Officer', rankType: 'Officer', retireeAge: 52},
  {value: 17, label: 'Brigadier Officer', rankType: 'Officer', retireeAge: 54},
  {value: 18, label: 'General Officer', rankType: 'Officer', retireeAge: 56},
  {value: 19, label: 'Marshal Officer', rankType: 'Officer', retireeAge: 65},
  {value: 20, label: 'First Marshal Officer', rankType: 'Officer', retireeAge: 65},
  {value: 21, label: 'Private Staff', rankType: 'Staff', retireeAge: 44},
  {value: 22, label: 'First Private Staff', rankType: 'Staff', retireeAge: 44},
  {value: 23, label: 'Corporal Staff', rankType: 'Staff', retireeAge: 46},
  {value: 24, label: 'Second Sergeant Staff', rankType: 'Staff', retireeAge: 48},
  {value: 25, label: 'Sergeant Staff', rankType: 'Staff', retireeAge: 50},
  {value: 26, label: 'First Sergeant Staff', rankType: 'Staff', retireeAge: 50},
  {value: 27, label: 'Staff Sergeant Staff', rankType: 'Staff', retireeAge: 52}
];

export const MilitaryArrayAb = [
  {value: 1, label: 'ملازم', rankType: 'Officer', retireeAge: 44},
  {value: 2, label: 'ملازم أول', rankType: 'Officer', retireeAge: 44},
  {value: 3, label: 'نقيب', rankType: 'Officer', retireeAge: 48},
  {value: 4, label: 'رائد', rankType: 'Officer', retireeAge: 50},
  {value: 5, label: 'مقدم', rankType: 'Officer', retireeAge: 52},
  {value: 6, label: 'عقيد', rankType: 'Officer', retireeAge: 54},
  {value: 7, label: 'عميد', rankType: 'Officer', retireeAge: 56},
  {value: 8, label: 'لواء', rankType: 'Officer', retireeAge: 58},
  {value: 9, label: 'فريق', rankType: 'Officer', retireeAge: 65},
  {value: 10, label: 'فريق أول', rankType: 'Officer', retireeAge: 65},
  {value: 11, label: 'ملازم', rankType: 'Officer', retireeAge: 42},
  {value: 12, label: 'ملازم أول', rankType: 'Officer', retireeAge: 42},
  {value: 13, label: 'نقيب', rankType: 'Officer', retireeAge: 46},
  {value: 14, label: 'رائد', rankType: 'Officer', retireeAge: 48},
  {value: 15, label: 'مقدم', rankType: 'Officer', retireeAge: 50},
  {value: 16, label: 'عقيد', rankType: 'Officer', retireeAge: 52},
  {value: 17, label: 'عميد', rankType: 'Officer', retireeAge: 54},
  {value: 18, label: 'لواء', rankType: 'Officer', retireeAge: 56},
  {value: 19, label: 'فريق', rankType: 'Officer', retireeAge: 65},
  {value: 20, label: 'فريق أول', rankType: 'Officer', retireeAge: 65},
  {value: 21, label: 'جندي', rankType: 'Staff', retireeAge: 44},
  {value: 22, label: 'جندي أول', rankType: 'Staff', retireeAge: 44},
  {value: 23, label: 'عريف', rankType: 'Staff', retireeAge: 46},
  {value: 24, label: 'وكيل رقيب', rankType: 'Staff', retireeAge: 48},
  {value: 25, label: 'رقيب', rankType: 'Staff', retireeAge: 50},
  {value: 26, label: 'رقيب أول', rankType: 'Staff', retireeAge: 50},
  {value: 27, label: 'رئيس رقباء', rankType: 'Staff', retireeAge: 52}
];

export const salaryOptions = [
  {value: 'Rajhi', label: "Rajhi"},
  {value: 'SNB', label: "SNB"},
  {value: 'Bilad', label: "Bilad"},
  {value: 'Riyadh', label: "Riyadh"},
  {value: 'Fransi', label: "Fransi"},
  {value: 'ANB', label: "ANB"},
  {value: 'SAB', label: "SAB"},
  {value: 'Al Jazirah', label: "Al Jazirah"},
  {value: 'Emirates NBD', label: "Emirates NBD"}
]
export const salaryOptionsAb = [
  {value: 'Rajhi', label: "مصرف الراجحي"},
  {value: 'SNB', label: "البنك الأهلي السعودي"},
  {value: 'Bilad', label: "بنك البلاد"},
  {value: 'Riyad', label: "بنك الرياض"},
  {value: 'Fransi', label: "البنك السعودي الفرنسي"},
  {value: 'ANB', label: "البنك العربي الوطني"},
  {value: 'SAB', label: "البنك السعودي البريطاني - ساب"},
  {value: 'Al Jazirah', label: "بنك الجزيرة"},
  {value: 'Emirates NBD', label: "بنك الإمارات الوطني"},
]

export const loanTypes = [
  {value: 1, label: "Personal"},
  {value: 2, label: "Car"},
  {value: 3, label: "Mortgage"},
  {value: 4, label: "Credit card"},
  {value: 5, label: "Emkan"}
]
export const loanTypesAb = [
  {value: 1, label: "شخصي"},
  {value: 2, label: "سيارة"},
  {value: 3, label: "عقاري"},
  {value: 4, label: "بطاقة إئتمان"},
  {value: 5, label: "إمكان"}
]

export const typesOfMortgageDropDownValues = [
  {value: 1, label: "typeOfMortgageOptions.readyUnit"},
  {value: 2, label: "typeOfMortgageOptions.saleOnTheMap"},
  {value: 3, label: "typeOfMortgageOptions.selfBuild"},
  {value: 4, label: "typeOfMortgageOptions.other"}
]

export const typesOfLoansDropDownValues = [
  {value: 1, label: "typeOfLoansOptions.personalLoan"},
  {value: 2, label: "typeOfLoansOptions.mortgageLoan"},
  {value: 3, label: "typeOfLoansOptions.carLoan"},
  {value: 4, label: "typeOfLoansOptions.EmkanLoan"},
  {value: 5, label: "typeOfLoansOptions.creditCardLoan"},
  // {value: 6, label: "typeOfLoansOptions.other"},
]
